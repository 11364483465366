let numberOfRows = 0

$(function () {
    numberOfRows = $('.catalog-item-form_add-row').length - 1
    let clone = $('#attributes-search-form').clone();
    $('#attributes-search-form-placeholder').append(clone)
    $('#attributes-search-form').remove()
})

$(document).on('click', '.catalog-item-form_remove', function () {
    this.closest('.catalog-item-form_add-row').remove()
})

$(document).on('click', '#catalog-item-form_add', function () {
    let clone = $('#attributes-search-form-placeholder').clone();

    clone.find('[id]').addBack().attr('id', function (_, id) {
        return id + '-' + numberOfRows.toString()
    });

    clone.find('[for]').addBack().attr('for', function (_, for_attr) {
        return for_attr + '-' + numberOfRows.toString()
    });

    clone.find('[name]').attr('name', function (_, name) {
        return name + '[' + numberOfRows + ']'
    });

    clone.find('#search_target_types-' + numberOfRows).on('change', function () {
        let curr_id = this.id.match(/\d+/)[0]
        const input = clone.find('#search_target_ids-' + curr_id)
        const search_target_values_input = clone.find('#search_target_option_ids-' + curr_id)
        input.val(null).trigger('change')
        input.find('option').remove();

        if (this.value) {
            input.closest('.col-md-4').removeClass('d-none')
            search_target_values_input.val(null).trigger('change')
            $(input).select2(
                {
                    allowClear: true,
                    placeholder: 'Search ...',
                    ajax: {
                        url: this.value === 'Collectibles::Property' ? '/searches/properties' : '/searches/variation_attributes',
                        dataType: 'json',
                        delay: 1000,
                        data: function (params) {
                            return {
                                q: params.term, // search term
                                page: params.page
                            }
                        },
                    }
                }
            ).on('change.select2', function () {
                let curr_id = this.id.match(/\d+/)[0]
                const input = clone.find('#search_target_option_ids-' + curr_id)
                const type_input = clone.find('#search_target_types-' + curr_id)
                input.val(null).trigger('change')
                input.find('option').remove();

                if (this.value) {
                    const property_id = this.value
                    input.closest('.col-md-4').removeClass('d-none')
                    $(input).select2(
                        {
                            allowClear: true,
                            placeholder: 'Search ...',
                            ajax: {
                                url: type_input.val() === 'Collectibles::Property' ? `/searches/property_options` : `/searches/variation_attribute_options`,
                                dataType: 'json',
                                delay: 1000,
                                data: function (params) {
                                    return {
                                        q: params.term, // search term
                                        page: params.page,
                                        property_id: property_id,
                                        variation_attribute_id: property_id
                                    }
                                },
                            }
                        }
                    ).on('select2:select', function (el) {
                        let curr_id = this.id.match(/\d+/)[0]
                        const input = clone.find('#search_target_values-' + curr_id)
                        input.val(el.params.data.text)
                    })

                    input.select2('open')
                } else {
                    input.closest('.col-md-4').addClass('d-none')
                }
            })
            input.select2('open')
        } else {
            input.closest('.col-md-4').addClass('d-none')
        }
    })

    $(clone).insertBefore($('#catalog-item-form_add-row'))
    numberOfRows++
})
