import jQuery from 'jquery'
import select2 from 'select2'
import Rails from "@rails/ujs"
import 'popper.js'
import 'bootstrap'
import 'startbootstrap-sb-admin/js/sb-admin'
import './nested_form/nested_form'

import '../styles/admin.scss'
import "chartkick/chart.js"
import datetimepicker from 'pc-bootstrap4-datetimepicker'
import 'tinymce'
import 'tinymce/plugins/code'
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/link'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/table'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'


// --------------------------------
// Custom scripts
// --------------------------------

import './catalog_item'

Rails.start()

window.select2Reinitialize = function() {
  $('.select2').select2()
  $('.select2-ajax').each(function() {
    $(this).select2(
      {
        ajax: {
          url: $(this).data('url'),
          dataType: 'json',
          delay: 1000,
          data: function(params) {
            return {
              q: params.term, // search term
              page: params.page
            }
          },
        }
      }
    )
  })
}

$(function () {
  $('.datepicker').datetimepicker({ format: 'YYYY-MM-DD' })

  $(document).on('change', '#card_sport_id', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#variation_sport_id', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#card_suite_id', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#pop_report_sport_id', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#pop_report_suite_id', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#pop_report_variation_id', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#catalog_item_category_id', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#item_schema_category_id', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#catalog_item_variation_category_id', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#catalog_item_variation_catalog_item_id', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#collectible_item_category_id', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#collectible_item_catalog_item_id', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#collectible_item_catalog_item_variation_id', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#dependency_rule_is_verifiable_external', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })
  $(document).on('change', '#dependency_rule_is_target_external', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#validation_rule_is_target_external', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#search_rule_is_dependency_external', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#search_rule_is_target_external', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '.is_target_external_search_schema', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#show_schema_category_id', function() {
    if (this.value === '') return

    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#scanning_result_schema_category_id', function() {
    if (this.value === '') return

    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  $(document).on('change', '#relationship_category_id', function() {
    var form = $(this).closest('form'),
      data = form.serialize()
    $.ajax({
      url: form.data('url'),
      data: data
    })
  })

  select2Reinitialize()
})
